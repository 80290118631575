import React from "react";

export const LeagueWrapper = ({ league, competition, children }) => {
    if(competition === "wsl" && league.code === "bringiton") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <p>Demonstration of custom wrapper for ShePlays internal league</p>
              <img className="img-fluid rounded-circle" src="/img/home2.jpg" alt="" />
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "e5ayy5") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://beyond90.com.au/" target="blank">
              <img className="responsive_league" src="/img/b90.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "STB") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <a href="https://www.youtube.com/c/shetalksball" target="blank">
              <img className="responsive_league" src="/img/STB.png" width="80%" alt="" />
              </a>
		<h4>League code: {league.code}</h4>
		<p>To learn more follow <a href ="https://twitter.com/SheTalksBall" target="blank">@SheTalksBall</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">sh
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "MUWSC") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <a href="https://twitter.com/MUW_SC" target="blank">
              <img className="responsive_league" src="/img/muwsc.jpg" width="30%" alt="" />
              </a>
		<h4>League code: {league.code} </h4>
		<p>To learn more follow <a href ="https://twitter.com/MUWSC" target="blank">@MUWSC</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "SpursLGBT") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <a href="https://twitter.com/SpursLGBT" target="blank">
              <img className="responsive_league" src="/img/spurslgbt.jpg" width="30%" alt="" />
              </a>
		<h4>League code: {league.code} </h4>
		<p>To learn more follow <a href ="https://twitter.com/SpursLGBT" target="blank">@SpursLGBT</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }


  if(competition === "wsl" && league.code === "LFCWSC") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <a href="https://twitter.com/LFCWSC" target="blank">
              <img className="responsive_league" src="/img/lfcwsc.jpeg" width="70%" alt="" />
		</a>
	     <h4>League code: {league.code} </h4>
		<p>To learn more follow <a href ="https://twitter.com/LVCWSC" target="blank">@LFCWSC</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "WFN") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <a href="https://twitter.com/WomensFootyNet" target="blank">
              <img className="responsive_league" src="/img/wfn.jpg" width="70%" alt="" />
              </a>
		<h4>League code: {league.code} </h4>
		<p>To learn more follow <a href ="https://twitter.com/WomensFootyNet" target="blank">@WomensFootyNet</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "FE22") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/FootballEngine1" target="blank">
              <img className="responsive_league" src="/img/fe2022.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "B90") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://beyond90.com.au/" target="blank">
              <img className="responsive_league" src="/img/b90.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "nwsl" && league.code === "ACFCPandaPlus") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/ACPandemonium" target="blank">
              <img className="responsive_league" src="/img/Pandas.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "nwsl" && league.code === "Riveters") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/PDXRivetersSG" target="blank">
              <img className="responsive_league" src="/img/riverters2.jpeg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "nwsl" && league.code === "Uproar") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/UproarNC" target="blank">
              <img className="responsive_league" src="/img/uproar_banner.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "nwsl" && league.code === "Rebellion") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/NWSL_LA" target="blank">
              <img className="responsive_league" src="/img/Rebellion.jpeg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "nwsl" && league.code === "Local134") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/ChicagoLocal134" target="blank">
              <img className="responsive_league" src="/img/Local134.png" width="60%" alt="" />
              </a>
              <p>To join Local 134 Supporters Group click <a href ="https://www.chicagolocal134.com/membership" target="blank">here!</a></p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
      }
          if(competition === "nwsl" && league.code === "m3opie") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/ChicagoLocal134" target="blank">
              <img className="responsive_league" src="/img/lavenderbanner.jpeg" width="60%" alt="" />
              </a>
              <p>Follow <a href ="https://twitter.com/ButchertownR" target="blank">Butchertown Rundown</a> and <a href ="https://twitter.com/LavenderLegion" target="blank">Lavender Legion</a> on X, formerly Twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
  }

  if(competition === "nwsl" && league.code === "L134") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/ChicagoLocal134" target="blank">
              <img className="responsive_league" src="/img/Local134.png" width="60%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "nwsl" && league.code === "OG23") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/SpiritSquadron" target="blank">
              <img className="responsive_league" src="/img/SpiritSquad.jpg" width="40%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }


  if(competition === "nwsl" && league.code === "cloud9") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/Cloud9GOTH" target="blank">
              <img className="responsive_league" src="/img/cloud9.jpeg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
}


  if(competition === "nwsl" && league.code === "BBFL") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://taplink.cc/bridgebrigade" target="blank">
              <img className="responsive_league" src="/img/BridgeBrigadeBanner.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
  }


  if(competition === "nwsl" && league.code === "rovalks") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <p>To learn more follow <a href ="https://twitter.com/rovalks" target="blank">@rovalks</a> on X, formerly twitter!</p>
              <a href="https://www.sounderatheart.com/tag/seattle-reign/" target="blank">
              <img className="responsive_league" src="/img/rovalks.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "CFCBLOGS") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2> 
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/ChelseaFCBlogs_" target="blank">
              <img className="responsive_league" src="/img/CFCB.jpg" width="40%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p /> 
            </div> 
          </> 
        );
    }

  if(competition === "wsl" && league.code === "WOSO") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/wosouk" target="blank">
              <img className="responsive_league" src="/img/wosouk.jpeg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "sheplays") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/sheplays_au" target="blank">
              <img className="responsive_league" src="/img/ShePlaysWSL_banner.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "CFCW") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/TheCFCWSocial" target="blank">
              <img className="responsive_league" src="/img/CFCW.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "MCWFCOSC") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <p>A league for Manchester City fans, brought to you by the MCWFC Official Supporters Club and Man City Women Fancast!</p>
              <div className="centre_div">
              <a href="https://twitter.com/MCWFC_OSC" target="blank">
              <img className="responsive_league_multiple" src="/img/mcwfc1.jpg" width="30%" alt="" />
              </a>
              <a href="https://twitter.com/mcwfancast" target="blank">
              <img className="responsive_league_multiple" src="/img/mcwfc2.jpg" width="30%" alt="" />
              </a>
              </div>
		<h4>League code: {league.code}</h4>
                <p>To learn more follow <a href ="https://twitter.com/MCWFC_OSC" target="blank">@MCWFC_OSC</a> on twitter!</p>

              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "miedemaftevans") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <div className="centre_div">
              <a href="https://www.instagram.com/miedemaftevans/" target="blank">
              <img className="responsive_league_multiple" src="/img/miedemaftevans.png" width="50%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "HGT") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <div className="centre_div">
              <a href="https://www.hergametoo.co.uk/" target="blank">
              <img className="responsive_league_multiple" src="/img/hgt.jpg" width="80%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "S71") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <div className="centre_div">
              <a href="https://since-71.com/" target="blank">
              <img className="responsive_league_multiple" src="/img/S71.jpg" width="80%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "Impetus") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <div className="centre_div">
              <a href="https://twitter.com/ImpetusFootball" target="blank">
              <img className="responsive_league_multiple" src="/img/Impetus.jpg" width="30%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "tillies") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <div className="centre_div">
              <a href="https://twitter.com/ImpetusFootball" target="blank">
              <img className="responsive_league_multiple" src="/img/tillies.jpg" width="50%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }


  if(competition === "wsl" && league.code === "mcy22v") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <div className="centre_div">
              <a href="https://twitter.com/ForzaWoso" target="blank">
              <img className="responsive_league_multiple" src="/img/Forzo2.jpg" width="80%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }


  if(competition === "wsl" && league.code === "18842004") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <div className="centre_div">
              <a href="https://twitter.com/LCFCWomenSC" target="blank">
              <img className="responsive_league_multiple" src="/img/LCFC.jpg" width="80%" alt="" />
              </a>
              </div>
		<h4>League code: {league.code}</h4>
                <p>To learn more follow <a href ="https://twitter.com/LCFCWomenSC" target="blank">@LCFCWomenSC</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "RMTV") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <div className="centre_div">
              <a href="https://twitter.com/RMTVWomensPod" target="blank">
              <img className="responsive_league_multiple" src="/img/RMTV.jpg" width="30%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }


  if(competition === "wsl" && league.code === "NSFA1957") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <p>League for members of the Northern Suburbs Football Association in Sydney!</p>
              <a href="https://nsfa.asn.au/" target="blank">
              <img className="responsive_league" src="/img/NSFA.jpg" width="40%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p/>
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "CWSG") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <p>A league for Chelsea fans, brought to you by the Chelsea Women Supporters Group!</p>
              <a href="https://www.chelseawomensupporters.com/" target="blank">
              <img className="responsive_league" src="/img/CWSG.jpg" width="30%" alt="" />
              </a>
		<h4>League code: {league.code}</h4>
		<p>To learn more follow <a href ="https://twitter.com/ChelseaWomenSG" target="blank">@ChelseaWomenSG</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
    if(competition === "uwcl" && league.code === "CWSG") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <p>A league for Chelsea fans, brought to you by the Chelsea Women Supporters Group!</p>
              <a href="https://www.chelseawomensupporters.com/" target="blank">
              <img className="responsive_league" src="/img/CWSG.jpg" width="30%" alt="" />
              </a>
		<h4>League code: {league.code}</h4>
		<p>To learn more follow <a href ="https://twitter.com/ChelseaWomenSG" target="blank">@ChelseaWomenSG</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "HFHSP") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/HerFootballHub" target="blank">
              <img className="responsive_league" src="/img/hfh.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
  
  if(competition === "wsl" && league.code === "TGTB") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <p></p>
              <a href="https://twitter.com/TGTalkBalls" target="blank">
              <img className="responsive_league" src="/img/tgtb.jpeg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
  }
  
  if(competition === "uwcl" && league.code === "TGTB") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <p></p>
              <a href="https://twitter.com/TGTalkBalls" target="blank">
              <img className="responsive_league" src="/img/tgtb.jpeg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

  if(competition === "wsl" && league.code === "sheplays2") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>League code: {league.code}</h4>
            <p>{league.description}</p>
            <a href="https://twitter.com/sheplays_au" target="blank">
            <img className="responsive_league" src="/img/sheplaysWSL2.png" width="80%" alt="" />
            </a>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }

  if(competition === "wsl" && league.code === "k8ip3p") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <p>Compete with your fellow Bluenoses</p>
              <div className="centre_div">
              <a href="https://twitter.com/BluesWomenSG" target="blank">
              <img className="responsive_league_multiple" src="/img/bwsg.jpg" width="30%" alt="" />
              </a>
              <a href="https://twitter.com/GreatSince68" target="blank">
              <img className="responsive_league_multiple" src="/img/gs68.jpg" width="30%" alt="" />
              </a>
              </div>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }    

  if(competition === "wsl" && league.code === "AVWSC") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <p>A league brought to you by the Aston Villa Women Supporters Club!</p>
              <a href="https://twitter.com/AVW_SC" target="blank">
              <img className="responsive_league" src="/img/avwsc.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }  

  if(competition === "wsl" && league.code === "GOTB") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="http://www.girlsontheball.com/" target="blank">
            <img className="responsive_league" src="/img/GOTB.jpg" width="60%" alt="" />
            </a>
	      <h4>League code: {league.code}</h4>
	      <p>To learn more follow @GirlsontheBall on <a href ="https://twitter.com/GirlsontheBall" target="blank">twitter</a> and <a href ="https://www.instagram.com/girlsontheball/" target="blank">instagram</a>!</p>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }  

  if(competition === "wsl" && league.code === "WTMKCFC") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="https://twitter.com/mowkingsmeadow" target="blank">
            <img className="responsive_league" src="/img/WTMK.jpg" width="80%" alt="" />
            </a>
	      <h4>League code: {league.code}</h4>
                <p>To learn more follow <a href ="https://twitter.com/mowkingsmeadow" target="blank">@mowkingsmeadow</a> on twitter!</p>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }


  if(competition === "wsl" && league.code === "c9ul4w") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h2>{league.description}</h2>
            <h4>League code: {league.code}</h4>
            <a href="https://www.girlsunitedfa.org/" target="blank">
            <img className="responsive_league" src="/img/GUFA.jpg" width="80%" alt="" />
            </a>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }

  if(competition === "wsl" && league.code === "ssArsenal") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h2>{league.description}</h2>
            <h4>League code: {league.code}</h4>
            <a href="https://twitter.com/arsenalwomensc" target="blank">
            <img className="responsive_league" src="/img/AWSCbig.jpg" width="80%" alt="" />
            </a>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }

  if(competition === "wsl" && league.code === "ShePlaysNWSL") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h2>{league.description}</h2>
            <h4>League code: {league.code}</h4>
            <a href="https://twitter.com/ShePlaysNWSL" target="blank">
            <img className="responsive_league" src="/img/nwsl-wsl.png" width="80%" alt="" />
            </a>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div> 
        </>
      );
  }


  if(competition === "wsl" && league.code === "HYT") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h2>{league.description}</h2>
            <h4>League code: {league.code}</h4>
            <a href="howsyourtouch.wixsite.com/blog" target="blank">
            <img className="responsive_league" src="/img/HYT23.jpg" width="80%" alt="" />
            </a>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }
  
    if(competition === "wwc" && league.code === "sheplays") {
        return (
          <>
            <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h2>{league.description}</h2>
            <h4>League code: {league.code}</h4>
            <a href="https://twitter.com/sheplays_au" target="blank">
              <img className="responsive_league" src="/img/sheplayswwcbanner.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
  
  if(competition === "wwc" && league.code === "TLL") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
            <h2>{league.description}</h2>
            <h4>League code: {league.code}</h4>
              <a href="https://theladiesleague.com/" target="blank">
              <img className="responsive_league" src="/img/TLL.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

if(competition === "uwcl" && league.code === "GOTB") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="http://www.girlsontheball.com/" target="blank">
            <img className="responsive_league" src="/img/GOTB.jpg" width="60%" alt="" />
            </a>
	      <h4>League code: {league.code}</h4>
	      <p>To learn more follow @GirlsontheBall on <a href ="https://twitter.com/GirlsontheBall" target="blank">twitter</a> and <a href ="https://www.instagram.com/girlsontheball/" target="blank">instagram</a>!</p>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }  

  if(competition === "uwcl" && league.code === "STB") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <a href="https://www.youtube.com/c/shetalksball" target="blank">
              <img className="responsive_league" src="/img/STB.png" width="80%" alt="" />
              </a>
		<h4>League code: {league.code}</h4>
		<p>To learn more follow <a href ="https://twitter.com/SheTalksBall" target="blank">@SheTalksBall</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">sh
              <p />
            </div>
          </>
        );
    }

  if(competition === "wwc" && league.code === "WFN") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h4>{league.description}</h4>
              <a href="https://twitter.com/WomensFootyNet" target="blank">
              <img className="responsive_league" src="/img/wfn.jpg" width="70%" alt="" />
              </a>
		<h4>League code: {league.code} </h4>
		<p>To learn more follow <a href ="https://twitter.com/WomensFootyNet" target="blank">@WomensFootyNet</a> on twitter!</p>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
    
    if(competition === "wwc" && league.code === "FE") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://twitter.com/FootballEngine1" target="blank">
              <img className="responsive_league" src="/img/FEbanner.jpeg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
    
      if(competition === "wsl" && league.code === "temaxx") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://www.notyouraverageenglandfan.com/" target="blank">
              <img className="responsive_league" src="/img/NYAEF.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
    
    if(competition === "wwc" && league.code === "hersport") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://t.co/O6QgMCiza6" target="blank">
              <img className="responsive_league" src="/img/hersportbanner2.png_medium" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }

    if(competition === "wwc" && league.code === "B90") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://beyond90.com.au/" target="blank">
              <img className="responsive_league" src="/img/b90.png" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
    
    if(competition === "wsl" && league.code === "thewososhow") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="https://linktr.ee/TheWOSOShow" target="blank">
            <img className="responsive_league" src="/img/wososhow.png" width="60%" alt="" />
            </a>
          <h4>League code: {league.code}</h4>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
    }
    
    if(competition === "uwcl" && league.code === "thewososhow") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="https://linktr.ee/TheWOSOShow" target="blank">
            <img className="responsive_league" src="/img/wososhow.png" width="60%" alt="" />
            </a>
          <h4>League code: {league.code}</h4>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
    }
    
    if(competition === "nwsl" && league.code === "wososhow") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="https://linktr.ee/TheWOSOShow" target="blank">
            <img className="responsive_league" src="/img/wososhow.png" width="60%" alt="" />
            </a>
          <h4>League code: {league.code}</h4>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }  
  if(competition === "wsl" && league.code === "lilys2324") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="https://www.instagram.com/lilyscafeandbar/" target="blank">
            <img className="responsive_league" src="/img/LILYS_pink_logo_white_background.jpg" width="60%" alt="" />
            </a>
          <h4>League code: {league.code}</h4>
          <p>Follow us here: <a href ="https://www.instagram.com/lilyscafeandbar/" target="blank">Instagram</a> and <a href ="https://www.instagram.com/lilyscafeandbar/" target="blank">Meetup</a></p>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
}  
  if(competition === "wsl" && league.code === "FFH") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4><a href ="https://www.fantasyfootballhub.co.uk/?via=sheplays" target="blank">Fantasy Football Hub</a> is purpose-built to turn fantasy managers into fantasy winners, using their industry leading AI.</h4>
            <h4>League code: {league.code}</h4>
            <a href="https://www.fantasyfootballhub.co.uk/?via=sheplays" target="blank">
            <img className="responsive_league" src="/img/FFH-ML.jpg" width="60%" alt="" />
            </a>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
  }  
  if(competition === "uwcl" && league.code === "EFF") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="https://linktr.ee/EraFutbolFemenino" target="blank">
            <img className="responsive_league" src="/img/EFF_banner_TEMP.jpg" width="60%" alt="" />
            </a>
          <h4>League code: {league.code}</h4>
		  <p>Bienvenidos a nuestra UWCL de Era Fútbol Femenino, un medio digital español con presencia en <a href ="https://twitter.com/Erafutbolfem" target="blank">Twitter</a> , <a href ="https://www.youtube.com/channel/UCh6cULKM07BYv4zLFG72bDw" target="blank">YouTube</a>,  <a href ="https://www.twitch.tv/erafutbolfemenino" target="blank">Twitch</a>, etc. Atrévete a jugar en nuestra liga y a crear el mejor equipo posible con un presupuesto limitado. ¡Te esperamos! </p>
		 <p>Welcome to the Era Fútbol Femenino Fantasy League. We are a digital media that on <a href ="https://twitter.com/Erafutbolfem" target="blank">Twitter</a> , <a href ="https://www.youtube.com/channel/UCh6cULKM07BYv4zLFG72bDw" target="blank">YouTube</a>,  <a href ="https://www.twitch.tv/erafutbolfemenino" target="blank">Twitch</a>,... We dare you to play in our league and to create the best team with a limited budget. Let's go!</p>
            <br></br>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            <p />
          </div>
        </>
      );
 }  
  if(competition === "uwcl" && league.code === "RMFI") {
      return (
        <>
          <div className="container clearfix mt-32">
            <h1>{league.name}</h1>
            <h4>{league.description}</h4>
            <a href="https://twitter.com/madridfeminfo" target="blank">
            <img className="responsive_league" src="/img/RMFI_banner.jpeg" width="60%" alt="" />
            </a>
          <h4>League code: {league.code}</h4>
		  <p>Minutaje de partidos, noticias de última hora, fichajes, estadísticas y más.</p>
		  <p>Live match updates, breaking news, transfers, statistics and more.</p>
          </div>
          <div className="container">{children}</div>
          <div className="container">
            
          </div>
        </>
      ); 
  } 
    if(competition === "wwc" && league.code === "SBFC") {
        return (
          <>
            <div className="container clearfix mt-32">
              <h1>{league.name}</h1>
              <h2>{league.description}</h2>
              <h4>League code: {league.code}</h4>
              <a href="https://linktr.ee/sheabutterfc" target="blank">
              <img className="responsive_league" src="/img/SBFCbanner.jpg" width="80%" alt="" />
              </a>
              <br></br>
            </div>
            <div className="container">{children}</div>
            <div className="container">
              <p />
            </div>
          </>
        );
    }
  return (
    <>
      <div className="container clearfix mt-32">
        <h1>{league.name}</h1>
        <h2>{league.description}</h2>
        <h4>League code: {league.code}</h4>
      </div>
      <div className="container">{children}</div>
      <div className="container">
        <p />
      </div>
    </>
  );
};
